/** @format */

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    vipDetail: {},
  },
  getters: {},
  mutations: {
    setVipDetail(state, payload) {
      state.vipDetail = payload
    },
  },
  actions: {
    // 查询全部数据
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/addUser', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // 活跃/not
    activeInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        console.log(params)
        axios
          .get('/user/activeUserList', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import axios from 'axios'

export default function useInvoicesList(proxy) {
  const toast = useToast()
  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions',
      label: '操作',
      thStyle: {
        width: '230px',
        textAlign: 'center'
      }
    },
    { key: 'avatar', label: '头像' },
    { key: 'id', label: '#' },
    { key: 'lastname', label: '名', formatter: val => `${val}` },
    { key: 'firstname', label: '姓', formatter: val => `${val}` },
    { key: 'gender', label: '性别' },
    // { key: 'active', label: '状态' },
    { key: 'mobile', label: '手机号' },
    { key: 'email', label: '邮箱' },
    { key: 'level.name', label: '等级' },
    { key: 'money', label: '佣金' },
    { key: 'all_tp', label: 'tp' },
    {
      key: 'pid',
      label: '推荐人',
      thStyle: {
        width: '120px',
        textAlign: 'center',
      },
    },
    { key: 'address', label: '家庭住址' },
  ]
  const perPage = ref(100)
  const page_size = ref(5)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 20, 25]
  const start_time = ref('')
  const end_time = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, start_time, end_time], () => {
    refetchData()
  })
  // 查询全部数据
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('vip-module/activeInvoices', {
        page_size: perPage.value,
        page: currentPage.value,
        start_time: start_time.value,
        end_time: end_time.value,
      })
      .then(response => {
        const { active } = response.data
        callback(active.list)
        totalInvoices.value = active.list.count
      })
  }
  // 数据删除
  const confirmDelete = val => {
    store
      .dispatch('vip-module/deleteInvoices', val)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除成功',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    start_time,
    end_time,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    statusFilter,
    refetchData,
    confirmDelete,
  }
}

<!-- @format -->

<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <flat-pickr class="form-control invoice-edit-input" style="width: 200px; margin-right: 25px; background-color: #fff" placeholder="点击选择开始日期" v-model="start_time" />
          <flat-pickr class="form-control invoice-edit-input" style="width: 200px; background-color: #fff" placeholder="点击选择结束日期" v-model="end_time" />
        </b-col>
      </b-row>
    </div>

    <b-table
      fixed
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      bordered
      style="vertical-align: middle !important; text-align: center"
    >
      <template #cell(active)="data">
        <b-badge variant="success"> 活跃 </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="d-flex align-items-center">
          <!--    编辑    -->
          <b-button variant="primary" size="sm" :to="{ path: '/general/member/member_list/edit', query: { id: data.item.id } }" @click.prevent="setVipDetail(data.item)">编辑 </b-button>
          <!--    删除    -->
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <b-button size="sm" variant="danger">删除</b-button>
            </template>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span @click="confirmDelete(data.item.id)">确认删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(avatar)="data">
        <img :src="data.item.avatar" alt="加载失败" style="width: 60px; height: 60px; display: inline-block; border-radius: 50%; box-shadow: 0 0 5px #ccc">
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BBadge } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import VipManagerModule from './VipManagerModule'
import useInvoicesList from './memberList'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    BBadge,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'vip-module'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, VipManagerModule)

    const statusOptions = ['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due']
    const setVipDetail = data => {
      data.gender = data.gender == '男' ? 1 : 2
      store.commit(`${INVOICE_APP_STORE_MODULE_NAME}/setVipDetail`, data)
    }
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      start_time,
      end_time,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      confirmDelete,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      start_time,
      end_time,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      setVipDetail,
      statusFilter,
      refetchData,
      statusOptions,
      confirmDelete,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
